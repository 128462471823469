import React from 'react';
import './App.css';

// logo

function App() {
  return (
    <div className="App">
      <div className="header">
        <p className="heading">Sarah is in Europe!*</p>
      </div>
      <div className="not-yet">
        <div className="not-yet-white">
          <p className="not-yet-text">*She’s not quite there yet, but check back here for updates. In the meantime, what should she do while she’s there?</p>
        </div>
      </div>
      <div id="form" className="middle">
        <div className="blue">
          <form name="contact" className="form" netlify-honeypot="bot-field" method="POST" netlify="true">
            <input type="hidden" name="form-name" value="contact" />
            <p className="not-yet-text smaller">
              Send Sarah Your Travel Advice:
            </p>
            <input className="contact-input" type="text" name="name" size="20" />
            <button className="submit-button" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
      <div className="not-yet-empty spacing" />
    </div>
  );
}

export default App;
